import i18n from "i18next"
import en from "./locales/en.json"
import zh from "./locales/zh.json"
//import LanguageDetector from "i18next-browser-languagedetector";
//.use(LanguageDetector)
i18n.init({
   fallbackLng: "en",
   lng: "en",
   resources: {
      en: {
         translation: en
      },
      zh: {
         translation: zh
      },
      saveMissing: true
   },
   debug: true,
   keySeperator: false,
   missingKeyHandler: (lng, ns, key, fallbackValue) => {
      return key + " does not exist"
   }
})

export {i18n}