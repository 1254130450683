import React, { useState, useRef, useEffect } from "react";
import { i18n } from "../i18n";
import { Field, ErrorMessage } from "formik";
import { Dialog } from "@reach/dialog";
import VisuallyHidden from "@reach/visually-hidden";
import CloseIcon from "../component/CloseIcon";
export default function(props) {
  const [editSalaryState, setEditSalaryState] = useState(false);
  const resetValue = useRef(null);
  function handleSalaryEdit(event) {
    event.preventDefault();

    setEditSalaryState(true);
  }
  function handleSalaryConfirm() {
    resetValue.current = null;

    setEditSalaryState(false);
  }
  function handleSave(event) {
    event.preventDefault();
    props.handleContinue(handleSalaryConfirm);
  }
  function handleSalaryCancel(event) {
    event.preventDefault();

    setEditSalaryState(false);
  }
  function handleSalaryDismiss() {
    setEditSalaryState(false);
  }
  function handleLabelClick() {
    setEditSalaryState(true);
  }
  useEffect(() => {
    if (editSalaryState === true) {
      // set initial value
      resetValue.current = {
        from: props.from,
        to: props.to,
        rate: props.rate,
        negotiable: props.negotiable,
        currency: props.currency,
        paymentMethod: props.paymentMethod
      };
    } else {
      if (resetValue.current) {
        // resetValue
        props.handleResetValue(resetValue.current);
      }
    }
  }, [editSalaryState]);
  return (
    <div className="row mb-3">
      <div className="col-sm-3 col-form-label" onClick={handleLabelClick}>
        {i18n.t("postJob.step3")}
      </div>

      <a
        className="col-sm-9 d-flex justify-content-between"
        style={{
          paddingTop: "calc(0.375rem + 1px)",
          paddingBottom: "calc(0.375rem + 1px)",
          borderBottom: "dashed 1px #0088cc",
          textDecoration: "none"
        }}
        href="#"
        onClick={handleSalaryEdit}
      >
        {props.negotiable
          ? i18n.t(`postJob.negotiable`)
          : i18n.t(`postJob.${props.currency}`) +
            "$" +
            props.from +
            (props.to ? " - $" + props.to : "") +
            "; " +
            i18n.t("payPeriod." + props.rate) +
            "; " +
            i18n.t(`payMethod.${props.paymentMethod}`)}
      </a>
      <Dialog isOpen={editSalaryState}>
        <div className="d-flex justify-content-end">
          <button type="button" className="close" onClick={handleSalaryDismiss}>
            <VisuallyHidden>Close</VisuallyHidden>
            <CloseIcon />
          </button>
        </div>
        <div className="form-group">
          <div className="custom-control custom-checkbox">
            <Field
              name="negotiable"
              className="custom-control-input"
              type="checkbox"
              id="negotiable-checkbox"
              checked={props.negotiable}
            />
            <label
              className="custom-control-label"
              htmlFor="negotiable-checkbox"
            >
              {i18n.t(`postJob.negotiable`)}
            </label>
          </div>
        </div>
        {!props.negotiable && (
          <>
            <div className="form-group">
              <div className="form-row align-items-center">
                <div className="col">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <div className="input-group-text bg-white">$</div>
                    </div>
                    <Field className="form-control" name="from"></Field>
                  </div>
                </div>
                <span>{i18n.t("simplePost.to")}</span>
                <div className="col">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <div className="input-group-text bg-white">$</div>
                    </div>
                    <Field className="form-control" name="to"></Field>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <ErrorMessage
                    className="invalid-feedback d-block"
                    name="from"
                    component="div"
                  />
                </div>
                <div className="col">
                  <ErrorMessage
                    className="invalid-feedback d-block"
                    name="to"
                    component="div"
                  />
                </div>
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="job-rate-id">
                {i18n.t("postJob.pay-frenquency")}
              </label>
              <Field
                component="select"
                name="rate"
                id="job-rate-id"
                className="form-control"
              >
                <option value="hourly">{i18n.t("payPeriod.hourly")}</option>
                <option value="daily">{i18n.t("payPeriod.daily")}</option>
                <option value="weekly">{i18n.t("payPeriod.weekly")}</option>
                <option value="monthly">{i18n.t("payPeriod.monthly")}</option>
                <option value="yearly">{i18n.t("payPeriod.yearly")}</option>
              </Field>
              <ErrorMessage
                className="invalid-feedback d-block"
                name="rate"
                component="div"
              />
            </div>
            <div className="form-group">
              <label htmlFor="currency">{i18n.t("postJob.currency")}</label>
              <Field
                id="currency"
                component="select"
                name="currency"
                className="form-control"
              >
                <option value="CAD">{i18n.t("postJob.CAD")}</option>
                <option value="USD">{i18n.t("postJob.USD")}</option>
              </Field>
              <ErrorMessage
                className="invalid-feedback"
                name="currency"
                component="div"
              />
            </div>

            <div className="form-group">
              <label htmlFor="payment-method">
                {i18n.t("postJob.pay-frenquency")}
              </label>
              <Field
                component="select"
                id="payment-method"
                name="paymentMethod"
                className="form-control"
              >
                <option value="Cheque">{i18n.t("payMethod.cheque")}</option>
                <option value="Cash">{i18n.t("payMethod.cash")}</option>
                <option value="Cheque Cash">
                  {i18n.t("payMethod.cheque/cash")}
                </option>
              </Field>
              <ErrorMessage
                className="invalid-feedback"
                name="paymentMethod"
                component="div"
              />
            </div>
          </>
        )}
        <div className="d-flex justify-content-end">
          <a href="#" className="btn btn-primary" onClick={handleSave}>
            {i18n.t("simplePost.save")}
          </a>
          <a href="#" className="btn btn-link" onClick={handleSalaryCancel}>
            {i18n.t("simplePost.cancel")}
          </a>
        </div>
      </Dialog>
    </div>
  );
}
