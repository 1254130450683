import React from "react";
import HirineLogo from "./img/HirineLogo@3x.png";
import DownloadCode from "./img/downloadAppCode@3x.png";
import AppleStoreIcon from "./img/appleStoreIcon@3x.png";
import GooglePlayIcon from "./img/googlePlayIcon@3x.png";
import CalendarIcon from "./img/CalendarIcon@3x.png";
import WorkTimeIcon from "./img/WorkTimeIcon@3x.png";
import PositionIcon from "./img/PositionIcon@3x.png";
import FooterHirineLogo from "./img/HirineFooterLogo@3x.png";
import parseISO from "date-fns/parseISO";
import { Link } from "@reach/router";
import { formatDistance } from "date-fns";
// import PostalCodeIcon from "./img/jobdetail/postalCodeIcon@3x.png";
import SatisfiedIcon from "./img/jobdetail/satisfied@3x.png";
import StarGray from "./img/jobdetail/starGray@3x.png";
import StarHighLighted from "./img/jobdetail/starHighlighted@3x.png";
import { i18n } from "./i18n";
import format from "date-fns/format";
import { toast } from "react-toastify";

class EmployerHeader extends React.Component {
  changeLanguage = event => {
    let lang = event.target.value;
    console.log("EmployerHeader changeLanguage:" + lang);

    i18n.changeLanguage(lang);
    document.cookie = `lang=${lang};`;
    localStorage.setItem("lang", lang);
    if (window.location.href.indexOf("/employer/login") > -1 || window.location.href.indexOf("/employer/register") > -1) {
      if (document.getElementsByName("account")) {
        document.getElementsByName("account")[0].click();
      }
    } else {
      toast.error("sorry, unknown language!", { className: "alert alert-danger" })
    }

    window.location.reload();
  };
  render() {
    return (
      <header
        className={
          "container card-shadow rounded-border-small white-background mt-0 mb-3"
        }
      >
        <div className="row d-flex justify-content-between px-3 py-2">
          <div className="d-flex align-items-center">
            <div className="">
              <Link to="/">
                <img
                  style={{ width: 100 }}
                  src={HirineLogo}
                  alt={"Hirine Logo"}
                />
              </Link>
            </div>
            <div className="ml-3 large-screen-item">
              <div style={{ color: "rgb(128,128,128)" }} className={""}>
                customer service
              </div>
            </div>
            <div className="ml-3 medium-screen-item">
              <div style={{ fontWeight: "bold", color: "rgb(100,100,100)" }}>
                1-844-844-7643
              </div>
            </div>
          </div>

          <div className="d-flex align-items-center">
            <div className="exlarge-screen-item mr-3">
              <img
                style={{ width: 100 }}
                className=""
                src="https://storage.googleapis.com/hr_api_storage/images/mmexport1575668018239.jpg"
                alt={"Scan Code To Download APP"}
              />
            </div>
            <div className="exlarge-screen-item mr-3">
              <div>
                <a href="https://apps.apple.com/us/app/hirine-employer/id1455051301?l=en&ls=1">
                  <img
                    style={{ width: 140 }}
                    className="mb-1"
                    src={AppleStoreIcon}
                    alt={"Apple Store Download Icon"}
                  />
                </a>
              </div>
              <div>
                <a href="https://play.google.com/store/apps/details?id=com.hrser_app&hl=en">
                  <img
                    style={{ width: 140 }}
                    className="mt-1"
                    src={GooglePlayIcon}
                    alt={"Google Play Store Download Icon"}
                  />
                </a>
              </div>
            </div>
            <div className="mr-3">
              <Link to="/employer/login">
                <button type="button" className="btn btn-outline-primary">
                  {i18n.t("employer-login")}
                </button>
              </Link>
            </div>
            <div>
              <select
                className={"custom-select"}
                onChange={this.changeLanguage}
              >
                <option value="" defaultValue>
                  {i18n.t("language")}
                </option>
                <option value="zh">{i18n.t("chinese")}</option>
                <option value="en">{i18n.t("english")}</option>
              </select>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

class Header extends React.Component {
  changeLanguage = event => {
    console.log("PageComponents Header changeLanguage:" + event.target.value);
    let lang = event.target.value;

    i18n.changeLanguage(lang);
    document.cookie = `lang=${lang};`;
    localStorage.setItem("lang", lang);
    // window.location.reload();
    if (lang === 'zh') {
      if (window.location.href.indexOf("/zh/") === -1) {
        window.location.href = window.location.href.replace("/job/", "/zh/job/");
      }
    } else if (lang === 'en') {
      if (window.location.href.indexOf("/zh/") > -1) {
        window.location.href = window.location.href.replace("/zh/", "/");
      }
    } else {
      toast.error("unknown language!", { className: "alert alert-danger" })
    }

  };
  render() {
    return (
      <header
        className={
          "container card-shadow rounded-border-small white-background mt-0 mb-3"
        }
      >
        <div className="row d-flex justify-content-between px-3 py-2">
          <div className="d-flex align-items-center">
            <div className="">
              <Link to="/">
                <img
                  style={{ width: 100 }}
                  src={HirineLogo}
                  alt={"Hirine Logo"}
                />
              </Link>
            </div>
            <div className="ml-3 large-screen-item">
              <div style={{ color: "rgb(128,128,128)" }} className={""}>
                customer service
              </div>
            </div>
            <div className="ml-3 medium-screen-item">
              <div style={{ fontWeight: "bold", color: "rgb(100,100,100)" }}>
                1-844-844-7643
              </div>
            </div>
          </div>

          <div className="d-flex align-items-center">
            <div className="exlarge-screen-item mr-3">
              <img
                style={{ width: 100 }}
                className=""
                src={DownloadCode}
                alt={"Scan Code To Download APP"}
              />
            </div>
            <div className="exlarge-screen-item mr-3">
              <div>
                <a href="http://onelink.to/gq6gbs">
                  <img
                    style={{ width: 140 }}
                    className="mb-1"
                    src={AppleStoreIcon}
                    alt={"Apple Store Download Icon"}
                  />
                </a>
              </div>
              <div>
                <a href="http://onelink.to/gq6gbs">
                  <img
                    style={{ width: 140 }}
                    className="mt-1"
                    src={GooglePlayIcon}
                    alt={"Google Play Store Download Icon"}
                  />
                </a>
              </div>
            </div>
            <div className="mr-3">
              <Link to="/employer/login">
                <button type="button" className="btn btn-outline-primary">
                  {i18n.t("employer-login")}
                </button>
              </Link>
            </div>
            <div>
              <select
                className={"custom-select"}
                onChange={this.changeLanguage}
              >
                <option value="" defaultValue>
                  {i18n.t("language")}
                </option>
                <option value="zh">{i18n.t("chinese")}</option>
                <option value="en">{i18n.t("english")}</option>
              </select>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

class Footer extends React.Component {
  render() {
    return (
      <footer className="mt-auto">
        <div className="container medium-screen-item card-shadow rounded-border-small white-background mt-3">
          <div className="row pt-4">
            <div className="col-3" />
            <div className="col-3">
              <img
                style={{ width: "100%" }}
                src={DownloadCode}
                alt="Scan Code To Download app"
              />
            </div>
            <div className="col-3 d-flex flex-column justify-content-end">
              <a href="http://onelink.to/gq6gbs">
                <img
                  style={{ width: "100%" }}
                  src={AppleStoreIcon}
                  alt="Apple Store Download Icon"
                />
              </a>
              <a href="http://onelink.to/gq6gbs">
                <img
                  style={{ width: "100%" }}
                  className="mt-3 mb-3"
                  src={GooglePlayIcon}
                  alt="Google Play Store Download Icon"
                />
              </a>
            </div>
            <div className="col-3 mt-3 d-flex flex-column justify-content-end">
              <small style={{ color: "rgb(128,128,128)", fontSize: "0.6rem" }}>
                customer service
              </small>
              <small
                style={{
                  fontWeight: "bold",
                  color: "rgb(100,100,100)",
                  fontSize: "0.7rem"
                }}
              >
                1-844-844-7643
              </small>
            </div>
          </div>
          <div className="row mt-3 align-items-center">
            <div className="col-3 mb-4" />
            <div className="col-3 mb-4 d-flex justify-content-center">
              <div style={{ color: "rgb(180,180,180)" }}>
                <small>copy right by hrmile technology</small>
              </div>
            </div>
            <div className="col-3 mb-4">
              <div
                className="d-flex justify-content-between"
                style={{ color: "rgb(180,180,180)" }}
              >
                <small>privacy policy</small>
                <small className={"mr-3"}>terms of use</small>
              </div>
            </div>
            <div className="col-3 mb-4">
              <div className="d-flex">
                {/* <div>
                           ICON
                        </div>
                        <div>
                           ICON
                        </div>
                        <div>
                           ICON
                        </div> */}
              </div>
            </div>
          </div>
        </div>
        <div
          style={{ paddingBottom: "5rem" }}
          className="small-screen-only-item"
        >
          <div className="d-flex justify-content-center align-items-center mt-2">
            Hirine c 2019 Developed by Hirine
          </div>
        </div>
        <div
          style={{ position: "fixed", bottom: 0, left: 0, right: 0, zIndex: 2 }}
          className={
            "small-screen-only-item container white-background card-shadow"
          }
        >
          <div className="py-2 px-3 d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <a href="http://onelink.to/gq6gbs">
                <img
                  style={{ width: 48 }}
                  src={FooterHirineLogo}
                  alt={"Hirine Logo"}
                />
              </a>
              <div className="ml-3" style={{ maxWidth: 200 }}>
                <div></div>
                <div></div>
              </div>
            </div>
            <div>
              <a href="http://onelink.to/gq6gbs">
                <button className="btn btn-primary">{i18n.t("open")}</button>
              </a>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}
class EmployerFooter extends React.Component {
  render() {
    return (
      <footer className="mt-auto">
        <div className="container medium-screen-item card-shadow rounded-border-small white-background mt-3">
          <div className="row pt-4">
            <div className="col-3" />
            <div className="col-3">
              <img
                style={{ width: "100%" }}
                src="https://storage.googleapis.com/hr_api_storage/images/mmexport1575668018239.jpg"
                alt="Scan Code To Download app"
              />
            </div>
            <div className="col-3 d-flex flex-column justify-content-end">
              <a href="https://apps.apple.com/us/app/hirine-employer/id1455051301?l=en&ls=1">
                <img
                  style={{ width: "100%" }}
                  src={AppleStoreIcon}
                  alt="Apple Store Download Icon"
                />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.hrser_app&hl=en">
                <img
                  style={{ width: "100%" }}
                  className="mt-3 mb-3"
                  src={GooglePlayIcon}
                  alt="Google Play Store Download Icon"
                />
              </a>
            </div>
            <div className="col-3 mt-3 d-flex flex-column justify-content-end">
              <small style={{ color: "rgb(128,128,128)", fontSize: "0.6rem" }}>
                customer service
              </small>
              <small
                style={{
                  fontWeight: "bold",
                  color: "rgb(100,100,100)",
                  fontSize: "0.7rem"
                }}
              >
                1-844-844-7643
              </small>
            </div>
          </div>
          <div className="row mt-3 align-items-center">
            <div className="col-3 mb-4" />
            <div className="col-3 mb-4 d-flex justify-content-center">
              <div style={{ color: "rgb(180,180,180)" }}>
                <small>copyright by hrmile technology</small>
              </div>
            </div>
            <div className="col-3 mb-4">
              <div
                className="d-flex justify-content-between"
                style={{ color: "rgb(180,180,180)" }}
              >
                <small>privacy policy</small>
                <small className={"mr-3"}>terms of use</small>
              </div>
            </div>
            <div className="col-3 mb-4">
              <div className="d-flex">
                {/* <div>
                           ICON
                        </div>
                        <div>
                           ICON
                        </div>
                        <div>
                           ICON
                        </div> */}
              </div>
            </div>
          </div>
        </div>
        <div
          style={{ paddingBottom: "5rem" }}
          className="small-screen-only-item"
        >
          <div className="d-flex justify-content-center align-items-center mt-2">
            Hirine c 2019 Developed by Hirine
          </div>
        </div>
        <div
          style={{ position: "fixed", bottom: 0, left: 0, right: 0, zIndex: 2 }}
          className={
            "small-screen-only-item container white-background card-shadow"
          }
        >
          <div className="py-2 px-3 d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <a href="http://onelink.to/gq6gbs">
                <img
                  style={{ width: 48 }}
                  src={FooterHirineLogo}
                  alt={"Hirine Logo"}
                />
              </a>
              <div className="ml-3" style={{ maxWidth: 200 }}>
                <div></div>
                <div></div>
              </div>
            </div>
            <div>
              <a href="http://onelink.to/gq6gbs">
                <button className="btn btn-primary">{i18n.t("open")}</button>
              </a>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

class JobCard extends React.Component {
  render() {
    let locale;
    if (i18n.language === "zh") {
      locale = require("date-fns/locale/zh-CN");
    } else {
      locale = require("date-fns/locale/en-US");
    }

    let workingDays = "";
    this.props.workingDays.forEach(day => {
      workingDays += i18n.t("weekdays." + day) + ", ";
    });

    return (
      <li>
        <div
          onClick={this.props.onClick}
          className="container card-shadow rounded-border-small white-background mt-0 mb-3 pt-2"
        >
          <div
            className="row d-flex justify-content-end mr-3"
            style={{ color: "rgb(190,190,190)" }}
          >
            {formatDistance(new Date(this.props.postDate), Date.now(), {
              locale: locale,
              addSuffix: true
            })}
          </div>
          <div
            style={{ maxWidth: "100%" }}
            className="row justify-content-between ml-4 mt-2"
          >
            <h4
              className="font-size-1-3"
              style={{ overflowWrap: "break-word", maxWidth: "100%" }}
            >
              {" "}
              {this.props.jobTitle}{" "}
            </h4>
          </div>
          <div className="row ml-2">
            <div className="col-sm-5">
              <div>
                <TagWithBG
                  textColor={"rgb(0,140,255)"}
                  className="mr-3 mb-3 mt-2"
                  text={
                    this.props.salary.negotiable
                      ? i18n.t("postJob.negotiable")
                      : this.props.salary.value +
                      " " +
                      i18n.t("currency." + this.props.salary.currency) +
                      " " +
                      i18n.t(
                        "payPeriod." + this.props.salary.unit.toLowerCase()
                      )
                  }
                  backgroundColor={"rgb(235,240,255)"}
                />
                <TagWithBG
                  textColor={"rgb(105,160,85)"}
                  className="mb-3 mt-2"
                  text={i18n.t("jobType." + this.props.jobType)}
                  backgroundColor={"rgb(215, 234, 212)"}
                />
              </div>
              <div style={{ maxWidth: "80%" }} className="mt-1 d-flex">
                <div className="mr-3">
                  <img style={{ maxWidth: 20 }} src={CalendarIcon} alt='Calendar icon' />
                </div>
                <div>
                  {workingDays}
                  {this.props.workingTime.from +
                    " " +
                    i18n.t("to") +
                    " " +
                    this.props.workingTime.to}
                </div>
              </div>
              <div className="mt-1 d-flex">
                <div className="mr-3">
                  <img style={{ maxWidth: 20 }} src={WorkTimeIcon} alt='Clock icon' />
                </div>
                <div>
                  {new Date(this.props.startingDate).toLocaleString(
                    "en",
                    displayFullDate
                  )}
                </div>
              </div>
              <div className="mt-1 d-flex">
                <div className="mr-3">
                  <img style={{ maxWidth: 20 }} src={PositionIcon} alt='Map marker icon' />
                </div>
                <div>{this.props.position}</div>
              </div>
            </div>
            {this.props.jobDescription.length > 0 ? (
              <div className="col-sm-7 mt-2">
                <h4 style={{ fontWeight: "normal", fontSize: "1.2rem" }}>
                  {i18n.t("jobManage.description")}
                </h4>
                <p style={{ whiteSpace: "pre-line" }}>
                  {this.props.jobDescription}
                </p>
              </div>
            ) : null}
          </div>
          <div className="row ml-2">
            <div className="col mb-3 mt-4">
              <img
                className="mr-3 rounded"
                style={{ maxWidth: 52, maxHeight: 52, display: "inline-block" }}
                src={this.props.companyLogo}
                alt="Company Logo"
              />
              {this.props.companyName}
              <button
                type="button"
                className="btn btn-primary float-right px-2 px-lg-4"
              >
                {i18n.t("jobDetail.apply")}
              </button>
            </div>
          </div>
        </div>
      </li>
    );
  }
}

class TagWithBG extends React.Component {
  render() {
    return (
      <div
        style={{
          whiteSpace: "nowrap",
          display: "inline-block",
          backgroundColor: this.props.backgroundColor,
          borderRadius: 5
        }}
        className={this.props.className}
      >
        <div
          style={{
            paddingLeft: 15,
            paddingRight: 15,
            paddingTop: 7,
            paddingBottom: 7,
            color: this.props.textColor
          }}
        >
          {this.props.text}
        </div>
      </div>
    );
  }
}

class HardSkill extends React.Component {
  render() {
    return (
      <li className="px-3 py-2 d-flex  col-xl-2 col-lg-3 col-md-4 col-12">
        <div className="mr-1">
          <img style={{ maxHeight: 18 }} src={SatisfiedIcon} alt='Satisfied icon' />
        </div>
        <div className="m-0">{this.props.name}</div>
      </li>
    );
  }
}

class SoftSkill extends React.Component {
  render() {
    let level = [];
    for (let i = 0; i < 5; i++) {
      if (i < this.props.value) {
        level.push(
          <div key={Math.random() + this.props.name + this.props.value}>
            <img
              className="mx-1"
              style={{ maxHeight: 24 }}
              src={StarHighLighted}
              alt='Highlight star icon'
            />
          </div>
        );
      } else {
        level.push(
          <div key={Math.random() + this.props.name + this.props.value}>
            <img
              className="mx-1"
              style={{ maxHeight: 24 }}
              src={StarGray}
              alt='Gray star icon'
            />
          </div>
        );
      }
    }
    return (
      <li className="p-3  col-xl-2 col-lg-3 col-md-4 col-12">
        <div className="pb-1 pl-1">{this.props.name}</div>
        <div className="d-flex">{level}</div>
      </li>
    );
  }
}
class SoftSkillNoStyle extends React.Component {
  render() {
    let level = [];
    for (let i = 0; i < 5; i++) {
      if (i < this.props.value) {
        level.push(
          <div key={Math.random() + this.props.name + this.props.value}>
            <img
              className="mx-1"
              style={{ maxHeight: 24 }}
              src={StarHighLighted}
              alt='Highlight star icon'
            />
          </div>
        );
      } else {
        level.push(
          <div key={Math.random() + this.props.name + this.props.value}>
            <img
              className="mx-1"
              style={{ maxHeight: 24 }}
              src={StarGray}
              alt='Gray star icon'
            />
          </div>
        );
      }
    }
    return (
      <>
        <div className="pb-1 pl-1">{this.props.name}</div>
        <div className="d-flex">{level}</div>
      </>
    );
  }
}

class EmployeeDetail extends React.Component {
  renderSkill(skills) {
    let reHard = [];
    let reSoft = [];
    skills.forEach(skill => {
      if (skill.type === "soft") {
        reSoft.push(
          <div style={{ minWidth: "50%" }} key={skill._id}>
            <SoftSkillNoStyle
              key={skill._id}
              name={i18n.language === "zh" ? skill.name_cn : skill.name}
              value={skill.value}
            />
          </div>
        );
      } else {
        reHard.push(
          <div style={{ minWidth: "50%" }} key={skill._id}>
            <span className="mr-1">
              <img style={{ maxHeight: 18 }} src={SatisfiedIcon} alt='Satisfied Icon' />
            </span>
            <span className="m-0">
              {i18n.language === "zh" ? skill.name_cn : skill.name}
            </span>
          </div>
        );
      }
    });
    return (
      <>
        <div className="d-flex flex-wrap my-3">{reHard}</div>
        <div className="d-flex flex-wrap my-3">{reSoft}</div>
      </>
    );
  }

  renderAdditionSkills(skills) {
    let reHard = [];
    let reSoft = [];
    skills.forEach(skill => {
      if (skill.type === "soft") {
        reSoft.push(
          <div style={{ minWidth: "50%" }} key={skill._id}>
            <SoftSkillNoStyle
              key={skill._id}
              name={i18n.language === "zh" ? skill.name_cn : skill.name}
              value={skill.value}
            />
          </div>
        );
      } else {
        reHard.push(
          <div key={skill._id} className="badge badge-tag m-1 p-2">
            {i18n.language === "zh" ? skill.name_cn : skill.name}
          </div>
        );
      }
    });
    return (
      <>
        <div className="h5 d-flex flex-wrap my-3">{reHard}</div>
        <div className="d-flex flex-wrap my-3">{reSoft}</div>
      </>
    );
  }

  render() {
    return (
      <div className="row">
        <div className="col-12 col-lg-4">
          {this.props.icon && (
            <div className="d-flex justify-content-center align-items-center py-3">
              <img
                alt='Avatar icon'
                src={this.props.icon}
                className="img-thumbnail rounded-circle"
                style={{ height: 150, width: 150 }}
              />
            </div>
          )}
          <div className="d-flex flex-column align-items-center">
            <h3>{this.props.employeeName}</h3>
            <div className="text-muted text-center pb-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-map-pin text-primary"
              >
                <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z" />
                <circle cx="12" cy="10" r="3" />
              </svg>
              <small className="ml-2">{this.props.address}</small>
            </div>
            <div className="pb-2">
              {this.props.email && (
                <div>
                  <span className="text-muted sr-only">Email:</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-mail text-primary"
                  >
                    <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" />
                    <polyline points="22,6 12,13 2,6" />
                  </svg>

                  <a
                    className="p-0 text-muted ml-2"
                    href={`mailto:${this.props.email}`}
                  >
                    <small>{this.props.email}</small>
                  </a>
                </div>
              )}
            </div>
            <div className="pb-2">
              {this.props.phone && (
                <div>
                  <span className="text-muted sr-only">phone:</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-phone text-primary"
                  >
                    <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
                  </svg>
                  <a
                    className="p-0 text-muted  ml-2"
                    href={`tel:${this.props.phone}`}
                  >
                    <small>+{this.props.phone}</small>
                  </a>
                </div>
              )}
            </div>
          </div>
          <div className="card border-0 shadow-sm">
            <div className="card-body">
              <h5 className="card-title">
                {i18n.t("jobDetail.addition-info")}
              </h5>
              <div className="list-group list-group-flush">
                {this.props.birthday && (
                  <div className="list-group-item d-flex justify-content-between px-0">
                    <span className="text-muted">
                      {i18n.t(`jobDetail.birthday`)}
                    </span>
                    {format(parseISO(this.props.birthday), "MMM dd, yyyy")}
                  </div>
                )}
              </div>
              <div className="list-group list-group-flush">
                {this.props.gender && (
                  <div className="list-group-item d-flex justify-content-between px-0">
                    <span className="text-muted">
                      {i18n.t(`jobDetail.gender`)}
                    </span>
                    {i18n.t(`jobDetail.${this.props.gender}`)}
                  </div>
                )}
              </div>
              {this.props.resume && (
                <div className="list-group list-group-flush">
                  <div className="list-group-item d-flex justify-content-between px-0">
                    <span className="text-muted">resume</span>
                    <a className="p-0" href={this.props.resume}>
                      Download
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div>{this.props.children}</div>
        </div>
        <div className="col-12 col-lg-8">
          {this.props.satisfiedSkills.length !== 0 && (
            <div className="card border-0 shadow-sm mt-3">
              <div className="card-body">
                <h5 className="card-title">
                  {i18n.t("jobDetail.skill")}{" "}
                  {this.props.satisfiedSkills.length} /{" "}
                  {this.props.satisfiedSkills.length +
                    this.props.notSatisfiedSkills.length}
                </h5>

                {this.props.satisfiedSkills.length !== 0 &&
                  this.renderSkill(this.props.satisfiedSkills)}

                {/* <div className="list-group list-group-flush">
                   {this.props.notSatisfiedSkills.length !== 0 &&
                     this.renderSkill(this.props.notSatisfiedSkills)}
                 </div> */}
              </div>
            </div>
          )}
          {this.props.additionalSkills.length !== 0 && (
            <div className="card border-0 shadow-sm mt-3">
              <div className="card-body">
                <h5 className="card-title">
                  {i18n.t("jobDetail.addition-skill")}
                </h5>
                {this.renderAdditionSkills(this.props.additionalSkills)}
              </div>
            </div>
          )}
          {this.props.introduction && this.props.introduction.length > 0 ? (
            <div className="card border-0 shadow-sm mt-3">
              <div className="card-body">
                <h5 className="card-title">
                  {i18n.t("jobDetail.introduction")}
                </h5>
                <p className="card-text">{this.props.introduction}</p>
              </div>
            </div>
          ) : null}
          {this.props.log && (
            <div className="card-body">
              <h5 className="card-title">{i18n.t("jobDetail.introduction")}</h5>
              <p className="card-text">{this.props.introduction}</p>
            </div>
          )}
        </div>
      </div>
    );
  }
}

EmployeeDetail.defaultProps = {
  satisfiedSkills: [],
  notSatisfiedSkills: [],
  additionalSkills: []
};

class ActivityIndicator extends React.Component {
  render() {
    return (
      <div className="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    );
  }
}

const displayFullDate = {
  year: "numeric",
  month: "numeric",
  day: "numeric"
};

export {
  EmployeeDetail,
  Header,
  Footer,
  TagWithBG,
  HardSkill,
  SoftSkill,
  SoftSkillNoStyle,
  ActivityIndicator,
  EmployerHeader,
  EmployerFooter
};
