import React, { Component } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Link } from "@reach/router";
import * as Api from "./Api";
import * as Yup from "yup";
import { navigate } from "@reach/router";
import { EmployerHeader, EmployerFooter } from "./PageComponents"
import { i18n } from "./i18n";
import { toast } from "react-toastify";

class Register extends Component {
  state = {
    count: 60,
    RegisterSchema: Yup.object().shape({
      account: Yup.string()
        .required(i18n.t("userInput.error.required"))
        .trim(),
      code: Yup.string()
        .required(i18n.t("userInput.error.required"))
        .trim()
        .matches(/^[0-9]{4}$/, "Invalid code"),
      password: Yup.string().required(i18n.t("userInput.error.required")),
      passwordRepeat: Yup.string()
        .oneOf([Yup.ref("password"), null], i18n.t("userInput.error.passwordMisMatch"))
        .required(i18n.t("userInput.error.required"))
    })
  };
  sendCode = value => {
    if (value && value.length !== 0) {
      const payload = {
        countryCode: "1",
        account: value
      };
      Api.sendCodeForRegister(payload)
        .then(result => {
          if (result && result.success) {
            toast.success("Send code successed!", {
              className: "alert alert-success"
            });
            this.startCountDown();
          } else {
            throw new Error("Send code failed");
          }
        })
        .catch(e => {
          toast.error(e.message, { className: "alert alert-danger" });
        });
    }
  };
  startCountDown = () => {
    this.setState(prevState => {
      if (prevState.count === 0) {
        return { count: 60 };
      } else {
        return { count: prevState.count - 1 };
      }
    });
    const countDown = setInterval(() => {
      this.setState(
        prevState => {
          if (prevState.count === 0) {
            return { count: 60 };
          } else {
            return { count: prevState.count - 1 };
          }
        },
        () => {
          if (this.state.count === 60) {
            clearInterval(countDown);
          }
        }
      );
    }, 1000);
  };

  selectLanguage= lang=> {
    i18n.changeLanguage(lang);
    document.cookie = `lang=${lang};`;
    // localStorage.setItem("lang", lang);
  };
  render() {
    let lang = localStorage.getItem("lang") ? localStorage.getItem("lang") : "en";
    this.selectLanguage(lang);
    return (
      <div className="page-background d-flex flex-column justify-content-start">
        <EmployerHeader/>
        {/* <Header /> */}
        <div className="container card-shadow rounded-border-small white-background">
          <div className="container pt-5 pb-5" style={{ maxWidth: 330 }}>
            <h1>{i18n.t("account.register")}</h1>
            <Formik
              initialValues={{
                account: "",
                password: "",
                passwordRepeat: "",
                code: ""
              }}
              onSubmit={(values, { setSubmitting }) => {
                Api.register({
                  ...values,
                  countryCode: 1
                })
                  .then(json => {
                    if (json && json._id && json._id.length !== 0) {
                      toast.success("Register successed! Logging in ...", {
                        className: "alert alert-success"
                      });
                      Api.login({
                        ...values,
                        countryCode: 1,
                        method: "password"
                      })
                        .then(json => {
                          setSubmitting(false);
                          if (json && json.token && json.token.length !== 0) {
                            this.props.login(json.token);
                            toast.success("Login successed!", {
                              className: "alert alert-success"
                            });
                          } else {
                            throw new Error("Login failed");
                          }
                        })
                        .catch(e => {
                          toast.error(e.message, { className: "alert alert-danger" });
                          setSubmitting(false);
                          navigate("/employer/login");
                        });
                    } else {
                      throw new Error("Register failed");
                    }
                  })
                  .catch(e => {
                    toast.error(e.message, { className: "alert alert-danger" });
                    setSubmitting(false);
                  });
              }}
              validationSchema={this.state.RegisterSchema}
            >
              {({ values, isSubmitting, errors, touched }) => (
                <Form>
                  <div className="form-group">
                    <label>{i18n.t("account.register")}</label>
                    <Field
                      name="account"
                      className={
                        "form-control" +
                        (errors.account && touched.account ? " is-invalid" : "")
                      }
                    />
                    <ErrorMessage
                      name="account"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>

                  <div className="form-group">
                    <label>{i18n.t("account.codeLabel")}</label>
                    <Field
                      name="code"
                      render={({ field }) => (
                        <div className="input-group">
                          <input
                            {...field}
                            type="text"
                            className={
                              "form-control" +
                              (errors.code && touched.code ? " is-invalid" : "")
                            }
                          />
                          <div className="input-group-append">
                            <button
                              type="button"
                              className="btn btn-outline-secondary"
                              onClick={() => {
                                this.sendCode(values.account);
                              }}
                              disabled={this.state.count !== 60}
                            >
                              {this.state.count === 60
                                ? i18n.t("account.sendCode")
                                : this.state.count}
                            </button>
                          </div>
                          <ErrorMessage
                            name="code"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      )}
                    />
                  </div>

                  <div className="form-group">
                    <label>{i18n.t("account.passwordLabel")}</label>
                    <Field
                      type="password"
                      name="password"
                      className={
                        "form-control" +
                        (errors.password && touched.password ? " is-invalid" : "")
                      }
                    />
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="form-group">
                    <label>{i18n.t("account.repeatPasswordLabel")}</label>
                    <Field
                      type="password"
                      name="passwordRepeat"
                      className={
                        "form-control" +
                        (errors.passwordRepeat && touched.passwordRepeat
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <ErrorMessage
                      name="passwordRepeat"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                  <button
                    type="submit"
                    className="btn btn-lg btn-primary btn-block"
                    disabled={isSubmitting}
                  >
                    {i18n.t("account.register")}
                  </button>
                </Form>
              )}
            </Formik>
            <div className="mt-3">
              <Link to="/employer/login">{i18n.t("account.login")}</Link>
            </div>
          </div>
        </div>
        {/* <Footer /> */}
        <EmployerFooter/>
      </div>
    );
  }
}

export default Register;
