import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
// import * as firebase from "firebase/app";
// import "firebase/messaging";
import { AuthProvider } from "./Auth-context";
import "@reach/dialog/styles.css";
import "@reach/tooltip/styles.css";
import "./custom.scss";
import { I18nextProvider } from "react-i18next";
import { i18n } from "./i18n";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.minimal.css";
import "react-datepicker/dist/react-datepicker.css";
import * as Sentry from "@sentry/browser";
// Sentry.init({dsn: "https://2079a4ef699e4c62aa0291481d1c3cb1@sentry.io/1780892"});

// firebase.initializeApp(config.firebase_config);
// const messaging = firebase.messaging();
// messaging.usePublicVapidKey(config.firebase_web_key);

ReactDOM.render(
  // <AuthProvider messaging={messaging}>
  <AuthProvider>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <I18nextProvider i18n={i18n}>
        <App />
        <ToastContainer
          className={"custom-toast-container"}
          closeButton={false}
          autoClose={3000}
        />
      </I18nextProvider>
    </MuiPickersUtilsProvider>
  </AuthProvider>,

  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
