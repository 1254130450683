import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import React, { Component } from "react";
import * as Api from "./Api";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from "react-places-autocomplete";
import { i18n } from "./i18n";
import addHours from "date-fns/addHours";
const InterviewSchema = Yup.object().shape({
  interviewTimes: Yup.array()
    .of(Yup.date().typeError("invalid date"))
    .min(1),
  interviewDuration: Yup.number("invalid")
    .positive()
    .default(60)
    .required(),
  interviewAddress: Yup.object().shape({
    description: Yup.string()
      .required()
      .min(1),
    latitude: Yup.number()
      .required()
      .notOneOf([0], "Invalid address"),
    longitude: Yup.number()
      .required()
      .notOneOf([0], "Invalid address")
  }),
  interviewContact: Yup.string().required(),
  interviewPhoneNumber: Yup.string().required(),
  showExpire: Yup.boolean()
    .required()
    .default(false),
  interviewExpireTime: Yup.date().when("showExpire", {
    is: true,
    then: Yup.date().required(),
    otherwise: Yup.date()
      .nullable()
      .notRequired()
  })
});

class SendInterview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addressValue:
        this.props.company &&
        this.props.company.address &&
        this.props.company.address.name
          ? this.props.company.address.name
          : ""
    };
  }
  customInput = ({ value, onClick }) => (
    <button
      className="picker-readOnly form-control"
      onClick={onClick}
      type="button"
    >
      {value}
    </button>
  );
  renderInterviewSlots = (errors, touched, handleRemove, values) => {
    const result = [];
    for (let i = 0; i < 3; i++) {
      if (values.interviewTimes[i]) {
        result.push(
          <div key={`input_interview_time_${i}`} className="form-group">
            <div className="input-group">
              <Field
                name={`interviewTimes[${i}]`}
                render={({ field, form }) => (
                  <div className="d-flex align-items-center w-100">
                    <DatePicker
                      selected={field.value === "date" ? null : field.value}
                      onChange={value => {
                        field.onChange(value);
                        form.setFieldValue(field.name, value);
                      }}
                      showTimeSelect
                      dateFormat="MMMM d, yyyy h:mm aa"
                      minDate={new Date()}
                      customInput={<this.customInput />}
                    />

                    {values.interviewTimes.length > 1 && (
                      <button
                        type="button"
                        style={{ height: 30, width: 30 }}
                        className="bg-danger text-white rounded-circle ml-4 d-flex align-items-center justify-content-center"
                        onClick={() => handleRemove(i, form)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-trash-2"
                        >
                          <polyline points="3 6 5 6 21 6" />
                          <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
                          <line x1="10" y1="11" x2="10" y2="17" />
                          <line x1="14" y1="11" x2="14" y2="17" />
                        </svg>
                      </button>
                    )}
                  </div>
                )}
              />
            </div>
            <ErrorMessage
              className="invalid-feedback d-block"
              name={`interviewTimes[${i}]`}
              component="div"
            />
          </div>
        );
      }
    }
    return result;
  };

  handleAddSlot = (values, setFieldValue) => {
    const temp = values.interviewTimes.slice();
    temp.push("date");
    setFieldValue("interviewTimes", temp);
  };
  handleRemoveSlot = (index, form) => {
    const temp = form.values.interviewTimes.slice();
    temp.splice(index, 1);
    form.setFieldValue("interviewTimes", temp);
    this.forceUpdate();
  };
  render() {
    return (
      <Formik
        initialValues={{
          interviewTimes: ["date"],
          interviewAddress: {
            description:
              this.props.company &&
              this.props.company.address &&
              this.props.company.address.name
                ? this.props.company.address.name
                : "",
            latitude:
              this.props.company &&
              this.props.company.address &&
              this.props.company.address.location &&
              this.props.company.address.location.coordinates
                ? this.props.company.address.location.coordinates[1]
                : 0,
            longitude:
              this.props.company &&
              this.props.company.address &&
              this.props.company.address.location &&
              this.props.company.address.location.coordinates
                ? this.props.company.address.location.coordinates[0]
                : 0
          },
          showExpire: true,
          interviewContact:
            this.props.company && this.props.company.companyName
              ? this.props.company.companyName
              : "",
          interviewPhoneNumber: this.props.company
            ? this.props.company.contact || this.props.company.mobile || ""
            : "",
          interviewDuration: 60,
          interviewExpireTime: addHours(new Date(), 4)
        }}
        validationSchema={InterviewSchema}
        onSubmit={(values, { setSubmitting }) => {
          const temp = values.interviewTimes.slice(0);
          Api.updateApplication(
            this.props.applicationId,
            {
              action: "sendInterview",
              interviewTimeOptions: values.interviewTimes,
              interviewContact: values.interviewContact,
              interviewPhoneNumber: values.interviewPhoneNumber,
              InterviewAddress: {
                name: values.interviewAddress.description,
                location: {
                  coordinates: [
                    values.interviewAddress.longitude,
                    values.interviewAddress.latitude
                  ],
                  type: "Point"
                }
              },
              interviewExpireTime: values.showExpire
                ? values.interviewExpireTime
                : temp.sort()[0],
              interviewDuration: values.interviewDuration
            },
            global._token
          )
            .then(json => {
              if (json._id) {
                setSubmitting(false);
                this.props.handleInterviewDialogClose();
                toast.success("Send interview successed!", {
                  className: "alert alert-success"
                });
              } else {
                throw new Error("Send interview Failed");
              }
            })
            .catch(e => {
              toast.error(e.message, { className: "alert alert-danger" });
              setSubmitting(false);
            });
        }}
      >
        {({ values, isSubmitting, errors, touched, setFieldValue }) => (
          <Form autoComplete="off">
            <div className="form-group">
              <label>{i18n.t("sendInterview.interview-time")}</label>

              {this.renderInterviewSlots(
                errors,
                touched,
                this.handleRemoveSlot,
                values
              )}
              {values.interviewTimes.length !== 3 && (
                <div className="d-flex justify-content-end">
                  <a
                    onClick={e => {
                      e.preventDefault();
                      this.handleAddSlot(values, setFieldValue);
                    }}
                    href="#"
                    className="d-flex align-items-center"
                  >
                    <span
                      className="rounded-circle d-flex align-items-center justify-content-center mr-1"
                      style={{
                        border: "1px solid #0083fd",
                        height: 32,
                        width: 32
                      }}
                    >
                      +
                    </span>
                    <span>{i18n.t("sendInterview.interview-time-add")}</span>
                  </a>
                </div>
              )}
            </div>
            <div className="form-group">
              <label>{i18n.t("sendInterview.interview-duration")}</label>
              <Field
                name="interviewDuration"
                className={
                  "form-control" +
                  (errors.interviewDuration && touched.interviewDuration
                    ? " is-invalid"
                    : "")
                }
              />
              <ErrorMessage
                className="invalid-feedback"
                name="interviewDuration"
                component="div"
              />
            </div>
            <div className="form-group" style={{ position: "relative" }}>
              <label>{i18n.t("sendInterview.interview-address")}</label>
              <Field
                name="interviewAddress"
                render={({ field, form }) => (
                  <PlacesAutocomplete
                    highlightFirstSuggestion={true}
                    // {...field}
                    value={this.state.addressValue}
                    debounce={1000}
                    onChange={value => {
                      this.setState({
                        addressValue: value
                      });
                      form.setFieldValue("interviewAddress.description", "");
                      form.setFieldValue("interviewAddress.latitude", 0);
                      form.setFieldValue("interviewAddress.longitude", 0);
                    }}
                    searchOptions={{
                      componentRestrictions: {
                        country: "ca"
                      },
                      types: ["address"]
                    }}
                    onSelect={(value, placeId) => {
                      if (!placeId) {
                      } else {
                        geocodeByAddress(value)
                          .then(result => {
                            this.setState({
                              addressValue: result[0].formatted_address
                            });
                            form.setFieldValue(
                              "interviewAddress.description",
                              result[0].formatted_address
                            );
                            return getLatLng(result[0]);
                          })
                          .then(({ lat, lng }) => {
                            form.setFieldValue(
                              "interviewAddress.latitude",
                              lat
                            );
                            form.setFieldValue(
                              "interviewAddress.longitude",
                              lng
                            );
                          });
                      }
                    }}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading
                    }) => (
                      <div
                        style={{
                          position: "relative"
                        }}
                      >
                        <input
                          {...getInputProps({
                            placeholder: i18n.t(
                              "sendInterview.interview-address-hint"
                            ),
                            className:
                              "form-control" +
                              (errors.interviewAddress &&
                              touched.interviewAddress
                                ? " is-invalid"
                                : "")
                          })}
                        />
                        <div
                          className="card"
                          style={{
                            position: "absolute",
                            top: "100%",
                            zIndex: 1,
                            marginTop: 8,
                            marginBottom: 8
                          }}
                        >
                          <div className="card rounded border-0">
                            <div className="list-group">
                              {loading && (
                                <div className="list-group-item">
                                  Loading...
                                </div>
                              )}
                              {suggestions.map(suggestion => {
                                const className = suggestion.active
                                  ? "list-group-item active"
                                  : "list-group-item";
                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      className
                                    })}
                                  >
                                    <span>{suggestion.description}</span>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                )}
              />
              <ErrorMessage
                className="invalid-feedback d-block"
                name="interviewAddress.latitude"
                component="div"
              />
            </div>

            <div className="form-group">
              <label>{i18n.t("sendInterview.contact-name")}</label>
              <Field
                name="interviewContact"
                className={
                  "form-control" +
                  (errors.interviewContact && touched.interviewContact
                    ? " is-invalid"
                    : "")
                }
              />
              <ErrorMessage
                className="invalid-feedback"
                name="interviewContact"
                component="div"
              />
            </div>
            <div className="form-group">
              <label>{i18n.t("sendInterview.contact")}</label>
              <Field
                name="interviewPhoneNumber"
                className={
                  "form-control" +
                  (errors.interviewPhoneNumber && touched.interviewPhoneNumber
                    ? " is-invalid"
                    : "")
                }
              />
              <ErrorMessage
                className="invalid-feedback"
                name="interviewPhoneNumber"
                component="div"
              />
            </div>

            {/* <div className="form-row">
              <div className="form-check">
                <Field
                  type="checkbox"
                  name="showExpire"
                  render={({ field, form }) => (
                    <input
                      checked={field.value}
                      name={field.name}
                      type="checkbox"
                      className="form-check-input"
                      id="show-expire"
                      onChange={event => {
                        if (event.target.checked === true) {
                          const temp = values.interviewTimes.slice(0);
                          form.setFieldValue(
                            "interviewExpireTime",
                            temp.sort()[0]
                          );
                        }
                        field.onChange(event);
                      }}
                    />
                  )}
                />
                <label className="form-check-label" htmlFor="show-expire">
                  {i18n.t("sendInterview.custom-expire")}
                </label>
              </div>
            </div> */}
            {values.showExpire ? (
              <div className="form-group">
                <label>{i18n.t("sendInterview.expire-time")}</label>
                <div className="w-100 d-flex">
                  <Field
                    name="interviewExpireTime"
                    render={({ field, form }) => (
                      <DatePicker
                        selected={field.value}
                        onChange={value => {
                          field.onChange(field.value);
                          form.setFieldValue(field.name, value);
                        }}
                        minDate={new Date()}
                        showTimeSelect
                        dateFormat="MMMM d, yyyy h:mm aa"
                        customInput={<this.customInput />}
                      />
                    )}
                  />
                  <ErrorMessage
                    className="invalid-feedback"
                    name="interviewExpireTime"
                    component="div"
                  />
                </div>
              </div>
            ) : null}
            <button
              type="submit"
              className="btn btn-primary mt-2"
              disabled={isSubmitting}
            >
              {i18n.t("sendInterview.send-interview")}
            </button>
          </Form>
        )}
      </Formik>
    );
  }
}

export default SendInterview;
