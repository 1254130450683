import React, { Component, useEffect } from "react";
import { AuthContext } from "./Auth-context";
import { Router, Redirect } from "@reach/router";
import PostJob from "./postv1/PostJob";
import JobBoard from "./JobBoard";
import Employee from "./Employee";
import Notification from "./Notification";
import { EmployeeJobDetail } from "./JobDetail";
import EmployerJobDetail from "./EmployerJobDetail";
import Profile from "./Profile";
import MySchedule from "./MySchedule";
import Application from "./Application";
import Login from "./Login";
import Register from "./Register";
import EmployerApp from "./EmployerApp";
import Home from "./Home";
import AddressBook from "./AddressBook";
import Draft from "./Draft";
import { i18n } from "./i18n";
import PayDashboard from "./PayDashboard";
import T4Dashboard from "./T4Dashboard";
import VacationPayDoneDashboard from "./VacationPayDoneDashboard";
import VacationPayPendingDashboard from "./VacationPayPendingDashboard";
import Chat from "./chat/Chat";
import Post from "./postv2/Post";
import ScrollToTop from "./component/ScrollToTop";
import SEORoot from "./component/SEORoot";
import About from "./About";
import Http404 from "./Http404";
import Announcement from "./Announcement";
function NotFound() {
  return <p>Sorry, nothing here</p>;
}

const ProtectedRoute = ({ component: Component, ...rest }) => (
  <AuthContext.Consumer>
    {context =>
      context.token ? (
        <Component {...rest} />
      ) : (
        <Redirect from="" to="/employer/login" noThrow />
      )
    }
  </AuthContext.Consumer>
);
const RedirectIfAuthRoute = ({ component: Component, ...rest }) => (
  <AuthContext.Consumer>
    {context =>
      !context.token ? (
        <Component {...rest} />
      ) : (
        <Redirect from="" to="/employer/job" noThrow />
      )
    }
  </AuthContext.Consumer>
);

class App extends Component {
  render() {
    return (
      <AuthContext.Consumer>
        {context => {
          return (
            <>
              <Router primary={false}>
                <SEORoot path="/">
                  <ScrollToTop path="/">
                    <Home path="/" language="en" />
                    {/* <EnglishSite path="/">
                      <Home path="/" language="en" />
                      <EmployeeJobDetail path="/job/:jobId" language="en" />
                      
                    </EnglishSite>
                    <ChineseSite path="/zh">
                      <Home path="/" language="zh" />
                      <EmployeeJobDetail path="/job/:jobId" language="zh" />
                    </ChineseSite> */}
                    <About path="/about" />
                    <EmployerApp
                      path="/employer"
                      logout={context.logout}
                      token={context.token}
                      company={context.company}
                      requested={context.requested}
                    >
                      <ProtectedRoute component={PostJob} path="/post" />
                      <ProtectedRoute
                        component={Post}
                        path="/post-new"
                        company={context.company}
                      />
                      <ProtectedRoute
                        component={Post}
                        path="/post-new/:jobId"
                        company={context.company}
                      />
                      <ProtectedRoute
                        component={Post}
                        path="/post-draft/:draftId"
                        company={context.company}
                      />
                      {/** draft list */}
                      <ProtectedRoute component={Draft} path="/draft" />
                      {/** draft detail */}
                      <ProtectedRoute
                        component={PostJob}
                        path="/draft/:draftId"
                      />
                      {/** edit job detail */}
                      <ProtectedRoute component={PostJob} path="/post/:jobId" />
                      {/** job list */}
                      <ProtectedRoute component={JobBoard} path="/job" />
                      <ProtectedRoute
                        component={Application}
                        path="/application/:applicationId"
                      />
                      <ProtectedRoute component={Employee} path="/employee" />
                      <ProtectedRoute
                        component={Notification}
                        path="/notification"
                      />
                      <ProtectedRoute
                        component={EmployerJobDetail}
                        path="/job/:jobId"
                        company={context.company}
                      />
                      <ProtectedRoute component={Profile} path="/profile" />
                      <ProtectedRoute component={MySchedule} path="/schedule" />
                      <ProtectedRoute component={AddressBook} path="/address" />
                      <ProtectedRoute
                        component={PayDashboard}
                        path="/payroll"
                      />
                      <ProtectedRoute
                        component={VacationPayDoneDashboard}
                        path="/vacationPayDone"
                      />
                      <ProtectedRoute
                        component={VacationPayPendingDashboard}
                        path="/vacationPayPending"
                      />
                      <ProtectedRoute component={T4Dashboard} path="/t4" />
                      <ProtectedRoute
                        component={Chat}
                        path="/chat"
                        userId={context.company && context.company._id}
                      />
                      <ProtectedRoute
                        component={Chat}
                        path="/chat/:target"
                        userId={context.company && context.company._id}
                      />
                      <ProtectedRoute
                        component={Announcement}
                        path="announcement"
                        userId={context.company && context.company._id}
                      />
                      <RedirectIfAuthRoute
                        component={Login}
                        login={context.login}
                        path="/login"
                      />
                      <RedirectIfAuthRoute
                        component={Register}
                        login={context.login}
                        path="/register"
                      />
                    </EmployerApp>
                    <NotFound default />
                    <Http404 path="/http404" />
                  </ScrollToTop>
                </SEORoot>
                <EmployeeJobDetail path="/jobDetail/:jobId" language="en" />
                <EmployeeJobDetail path="/job/:jobId" language="en" />
                <ChineseSite path="/zh">
                  <Home path="/" language="zh" />
                  <EmployeeJobDetail path="/job/:jobId" language="zh" />
                </ChineseSite>
              </Router>
            </>
          );
        }}
      </AuthContext.Consumer>
    );
  }
}

function EnglishSite(props) {
  useEffect(() => {
    i18n.changeLanguage("en");
  }, []);
  return props.children;
}
function ChineseSite(props) {
  useEffect(() => {
    i18n.changeLanguage("zh");
  }, []);
  return props.children;
}

export default App;
