import React, { Component } from "react";
import NetUtil from "./NetUtil";
import config from "./config";
import { Header, Footer, HardSkill, SoftSkillNoStyle } from "./PageComponents";
import PositionIcon from "./img/jobdetail/positionIcon@3x.png";
import JobTypeIcon from "./img/jobdetail/jobTypeIcon@3x.png";
import SalaryIcon from "./img/jobdetail/salaryIcon@3x.png";
import WorkingDayIcon from "./img/jobdetail/workingDayIcon@3x.png";
import ShiftIcon from "./img/jobdetail/shiftIcon@3x.png";
import { Helmet } from "react-helmet";
import { formatDistance } from "date-fns";
import { i18n } from "./i18n";
import SatisfiedIcon from "./img/jobdetail/satisfied@3x.png";
import { enUS, zhCN } from "date-fns/locale";

class EmployeeJobDetail extends Component {
  constructor(p) {
    super(p);
    this.state = {
      data: {}
    };
  }
  componentDidMount() {
    this.fetchJobDetail();
  }

  convertDataToObject(data) {
    return data;
  }

  fetchJobDetail() {
    let url = config.apiurl + "v1/common/job/" + this.props.jobId;
    NetUtil.get(url, null).then(result => {
      // console.log(result);
      if (result.hasOwnProperty("err_msg")) {
        alert(result.err_msg);
      } else {
        this.setState({ data: this.convertDataToObject(result) });
      }
    });
  }
  render() {
    return (
      <div className="container-fluid page-background d-flex flex-column justify-content-start">
        <Header />

        <div className="container white-background card-shadow rounded-border-small">
          {Object.keys(this.state.data).length > 0 ? (
            <JobInfo
              companyIcon={
                this.state.data.external_company_name
                  ? "https://storage.googleapis.com/hr_api_storage/default/img_default_employer.png"
                  : this.state.data.companyIcon
              }
              companyName={
                this.state.data.external_company_name
                  ? this.state.data.external_company_name
                  : this.state.data.companyName
              }
              jobTitle={this.state.data.title}
              positionTotal={this.state.data.positionTotal}
              positionAccepted={this.state.data.positionAccepted}
              type={this.state.data.type}
              salary={this.state.data.salary}
              shifts={this.state.data.shifts}
              workingTime={this.state.data.workingTime}
              workingDays={this.state.data.workingDays}
              startingDate={this.state.data.startingDate}
              createdAt={this.state.data.createdAt}
              mandatorySkills={this.state.data.mandatorySkillRequirements}
              preferedSkills={this.state.data.skillRequirements}
              jobDescription={this.state.data.description}
              addressName={this.state.data.address}
            />
          ) : null}
        </div>
        {/* addressName={this.state.data.address? this.state.data.address.name :""} */}

        <div className="d-flex justify-content-center mt-3">
          Download and Apply on Our App
        </div>
        <Footer />
      </div>
    );
  }
}

class JobInfo extends Component {
  renderSkills(skills) {
    // console.log(skills);
    let reHard = [];
    let reSoft = [];
    skills.forEach(skill => {
      if (skill.type === "soft") {
        reSoft.push(
          <div style={{ minWidth: "50%" }}>
            <SoftSkillNoStyle
              name={i18n.language === "zh" ? skill.name_cn : skill.name}
              value={0}
              key={skill._id}
            />
          </div>
        );
      } else {
        reHard.push(
          <div style={{ minWidth: "50%" }}>
            <span className="mr-1">
              <img style={{ maxHeight: 18 }} src={SatisfiedIcon} alt='Satisfied icon' />
            </span>
            <span className="m-0">
              {i18n.language === "zh" ? skill.name_cn : skill.name}
            </span>
          </div>
        );
      }
    });
    return (
      <>
        {reHard && reHard.length !== 0 && (
          <div className="d-flex flex-wrap my-3 w-100">{reHard}</div>
        )}
        {reSoft && reSoft.length !== 0 && (
          <div className="d-flex flex-wrap my-3 w-100">{reSoft}</div>
        )}
      </>
    );
  }

  render() {
    console.log("title:"+this.props.jobTitle+this.props.jobDescription);
    return (
      <div className="container py-5" style={{ maxWidth: 550 }}>
        <Helmet>
        <html lang={i18n.language === "en" ? "en-CA" : "zh"} />
          <title>
            {this.props.companyName} {i18n.t("seo.hiring")}{" "}
            {this.props.jobTitle} | Hirine
          </title>
          <meta
            name="description"
            content={
              this.props.companyName +
              " " +
              i18n.t("seo.hiring") +
              " " +
              this.props.jobTitle +
              " " +
              this.props.jobDescription
            }
          />
          <meta
          name="keywords"
          content={
            this.props.jobDescription
          }
        />
        </Helmet>
        <div className="mx-2 d-flex flex-column justify-content-center align-items-center text-center">
          <img
            className="mb-3 rounded"
            style={{ maxHeight: 84 }}
            src={this.props.companyIcon}
            alt={"company logo"}
          />
          <h1 className="mb-2 font-size-1-5">{this.props.jobTitle}</h1>
          <h2 className="text-muted h4">{this.props.companyName}</h2>
          <h2 className="text-muted h4" style={{display:"none"}}>{this.props.addressName? "Job location: "+this.props.addressName.name : "Job location: Great Toronto Area"}</h2>
        </div>
        
        <div className="mx-2 row py-1">
          <div className="col-xs-1">
            <img style={{ maxWidth: 18 }} src={PositionIcon} alt='Company position icon' />
          </div>
          <div className="col-11">
            {this.props.positionTotal === 0
              ? i18n.t("jobDetail.no-limit")
              : this.props.positionAccepted + " / " + this.props.positionTotal}
          </div>
        </div>
        <div className="mx-2 row py-1">
          <div className="col-xs-1">
            <img style={{ maxWidth: 18 }} src={JobTypeIcon} alt='Job type icon' />
          </div>
          <div className="col-11">{i18n.t(`jobType.${this.props.type}`)}</div>
        </div>
        <div className="mx-2 row py-1">
          <div className="col-xs-1">
            <img style={{ maxWidth: 18 }} src={SalaryIcon} alt='Salary icon' />
          </div>
          <div className="col-11">
            {this.props.salary && this.props.salary.negotiable ? (
              <span>{i18n.t("postJob.negotiable")}</span>
            ) : (
              <>
                <span>
                  {this.props.salary &&
                    i18n.t(`postJob.${this.props.salary.currency}`)}
                </span>
                <span className="ml-1">
                  {this.props.salary && this.props.salary.value}
                  {this.props.salary &&
                  this.props.salary.maxValue &&
                  this.props.salary.maxValue !== 0
                    ? " - " + this.props.salary.maxValue
                    : ""}
                </span>
                <span className="ml-1">
                  {this.props.salary &&
                    i18n.t(`payPeriod.${this.props.salary.unit}`)}
                </span>
              </>
            )}
          </div>
        </div>
        <div className="mx-2 row py-1">
          <div className="col-xs-1">
            <img style={{ maxWidth: 18 }} src={WorkingDayIcon} alt='Working day icon' />
          </div>
          <div className="col-11">
            {this.props.workingTime.from +
              " - " +
              this.props.workingTime.to +
              ", " +
              this.props.workingDays &&
              this.props.workingDays.length !== 0 &&
              this.props.workingDays
                .map(v => i18n.t(`weekdays.${v}`))
                .join(", ") +
                "  " +
                new Date(this.props.startingDate).toLocaleString(
                  "en",
                  displayFullDate
                )}
          </div>
        </div>
        <div className="mx-2 row mt-3 d-flex justify-content-between">
          <div style={{ color: "rgb(108,158,181)", fontSize: "0.8rem" }}>
            Posted{" "}
            {formatDistance(new Date(this.props.createdAt), Date.now(), {
              locale: enUS
            })}{" "}
            ago
          </div>
        </div>

        {this.props.mandatorySkills && this.props.mandatorySkills.length > 0 ? (
          <>
            <div className="mx-2 row mt-4">
              <h4 className="font-size-1-3 font-weight-normal">
                {i18n.t("jobManage.mandatory-skill")}
              </h4>
            </div>
            <div className="mx-2 row">
              {this.renderSkills(this.props.mandatorySkills)}
            </div>
          </>
        ) : null}

        {this.props.preferedSkills && this.props.preferedSkills.length > 0 ? (
          <>
            <div className="mx-2 row mt-4">
              <h4 className="font-size-1-3 font-weight-normal">
                {i18n.t("jobManage.preferred-skill")}
              </h4>
            </div>
            <div className="mx-2 row">
              <div className="d-flex flex-wrap my-3">
                {this.renderSkills(this.props.preferedSkills)}
              </div>
            </div>
          </>
        ) : null}

        {this.props.jobDescription && this.props.jobDescription.length > 0 ? (
          <>
            <div className="mx-2 row mt-3">
              <h3 className="font-size-1-3 font-weight-normal">
                {i18n.t("jobManage.description")}
              </h3>
            </div>
            <div className="mx-2 row" style={{ whiteSpace: "pre-line" }}>
              {this.props.jobDescription}
            </div>
          </>
        ) : null}
      </div>
    );
  }
}

const displayFullDate = {
  year: "numeric",
  month: "numeric",
  day: "numeric"
};

export { EmployeeJobDetail };
