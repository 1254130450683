const dev = {
  apiurl: "http://127.0.0.1:7001/",
  // apiurl:"http://64.225.7.33:7001/",
  googleAPIKey: "AIzaSyAQg7ahnNIZG-ZZTfuuLG1ZAkw8ZxVOybU",
  firebase_config: {
    apiKey: "AIzaSyAhVIl-gQh6EIhCqLzN71qEk_r2PsQNPYc",
    authDomain: "hrmile-app.firebaseapp.com",
    databaseURL: "https://hrmile-app.firebaseio.com",
    projectId: "hrmile-app",
    storageBucket: "hrmile-app.appspot.com",
    messagingSenderId: "421038032084",
    appId: "1:421038032084:web:bf43ee19bd243fb1"
  },
  commonId: "5d55b465deddef0035ea02e2",
  firebase_web_key:
    "BGmnppN0MZ-sFr2XhoKW1lHYUqKvdhlYbO74jbmTCu35UPIL9NqoBvkPZDgTpFedafhuY_4dJO3U6cWh8J-N7po"
};

const prod = {
  apiurl:"http://64.225.7.33:7001/",
  // apiurl: "https://production-api.hirine.com/",
  googleAPIKey: "AIzaSyAQg7ahnNIZG-ZZTfuuLG1ZAkw8ZxVOybU",
  firebase_config: {
    apiKey: "AIzaSyA6NyEwqWjTW-fju68ny-RB0IjUOyAfBqU",
    authDomain: "test-api-hr.firebaseapp.com",
    databaseURL: "https://test-api-hr.firebaseio.com",
    projectId: "test-api-hr",
    storageBucket: "test-api-hr.appspot.com",
    messagingSenderId: "347990287013",
    appId: "1:347990287013:web:efe7f21b8cdd8e19"
  },
  commonId: "5d55b465deddef0035ea02e2",
  firebase_web_key: "no key yet"
};

const config = process.env.NODE_ENV !== "production" ? dev : prod;

export default {
  ...config
};
