import React, { Component } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from "react-places-autocomplete";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import * as Api from "./Api";
import { i18n } from "./i18n";
import { toast } from "react-toastify";
const InfoSchema = Yup.object().shape({
  companyName: Yup.string().required().min(1, "invalid company name"),
  address: Yup.object().shape({
    description: Yup.string()
      .required()
      .min(1),
    latitude: Yup.number()
      .required()
      .notOneOf([0], "Invalid address"),
    longitude: Yup.number()
      .required()
      .notOneOf([0], "Invalid address")
  })
});
const EmailSchema = Yup.object().shape({
  email: Yup.string()
    .required("Required")
    .email()
    .trim(),
  code: Yup.string()
    .required("varification code is required")
    .matches(/^[0-9]{4}$/, "invalid code")
});

const PhoneSchema = Yup.object().shape({
  phone: Yup.string()
    .required("Required")
    .trim(),
  code: Yup.string()
    .required("varification code is required")
    .matches(/^[0-9]{4}$/, "invalid code")
});

export default class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addressValue: "",
      sendCodeEmailCount: 60,
      sendCodePhoneCount: 60,
      showEmailCode: false,
      showPhoneCode: false,
      data: {},
      success: false,
      fileName: ""
    };
    this.file = null;
  }

  componentDidMount() {
    this.refreshProfilePage();
  }

  refreshProfilePage() {
    Api.getCompanyInfo(global._token).then(result => {
      /** @todo (ren) fix this */
      if (result.createdAt) {
        this.setState({
          data: result,
          addressValue: (result.address && result.address.name) || ""
          //   originalData: JSON.parse(JSON.stringify(result))
        });
      }
    });
  }
  onFileSelect = e => {
    switch (e.target.name) {
      case "selectedFile":
        if (e.target.files.length > 0) {
          // Accessed .name from file
          const reader = new FileReader();
          reader.onload = e => {
            this.setState({ fileName: e.target.result, success: false });
          };
          reader.readAsDataURL(e.target.files[0]);
          this.file = e.target.files[0];
        }
      break;
      default:
    }
  };
  handleSubmitClick = () => {
    if (this.file) {
      Api.uploadIcon(this.file, global._token).then(response => {
        if (response.icon) {
          this.setState({ fileName: response.icon, success: true });
        }
      });
    }
  };

  sendCode = (value, type) => {
    if (value && value.length !== 0) {
      let payload;
      if (type === "email") {
        payload = {
          method: "email",
          email: value
        };
      } else if (type === "phone") {
        payload = {
          phone: value,
          countryCode: "1",
          method: "simple"
        };
      }
      Api.sendCodeForEditProfile(payload, global._token)
        .then(result => {
          if (result && result.success) {
            if (type === "email") {
              this.setState({ showEmailCode: true });
              this.startCountDown("sendCodeEmailCount");
            } else if (type === "phone") {
              this.setState({ showPhoneCode: true });
              this.startCountDown("sendCodePhoneCount");
            }
            toast.success("Send code successed!", {
              className: "alert alert-success"
            });
          } else {
            throw new Error("Send code failed");
          }
        })
        .catch(e => {
          toast.error(e.message, { className: "alert alert-danger" });
        });
    }
  };
  startCountDown = stateKey => {
    this.setState(prevState => {
      if (prevState[stateKey] === 0) {
        return { [stateKey]: 60 };
      } else {
        return { [stateKey]: prevState[stateKey] - 1 };
      }
    });
    const countDown = setInterval(() => {
      this.setState(
        prevState => {
          if (prevState[stateKey] === 0) {
            return { [stateKey]: 60 };
          } else {
            return { [stateKey]: prevState[stateKey] - 1 };
          }
        },
        () => {
          if (this.state[stateKey] === 60) {
            clearInterval(countDown);
          }
        }
      );
    }, 1000);
  };

  render() {
    if (Object.keys(this.state.data).length < 1) {
      return null;
    } else {
      return (
        <>
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
            <h1 className="h2 mb-0">{i18n.t("tabTitles.profile")}</h1>
          </div>
          <div className="row bg-white p-3 shadow-sm">
          <div className="col-lg-4 col-md-8 col-12">
              <div className="card">
                <div className="col-12 p-2 d-flex justify-content-center">
                  <img
                    src={
                      this.state.fileName
                        ? this.state.fileName
                        : this.state.data.icon
                    }
                    alt="company icon"
                    style={{ maxWidth: "100%" }}
                  />
                </div>
                <div className="card-body">
                  <div className="input-group mb-3">
                    <div className="custom-file">
                      <input
                        type="file"
                        name="selectedFile"
                        className="custom-file-input"
                        id="inputGroupFile02"
                        onChange={this.onFileSelect}
                        accept=".jpg, .jpeg, .png"
                        // value={this.state.fileName === ''? undefined: this.state.fileName}
                      />
                      <label
                        className="custom-file-label"
                        htmlFor="inputGroupFile02"
                        aria-describedby="inputGroupFileAddon02"
                      >
                        {i18n.t("profile.chooseFile")}
                      </label>
                    </div>
                    {this.state.fileName ? (
                      <div className="input-group-append">
                        <button
                          type="button"
                          className={
                            this.state.success
                              ? "btn btn-success"
                              : "btn btn-primary"
                          }
                          onClick={
                            this.state.success ? null : this.handleSubmitClick
                          }
                        >
                          {this.state.success
                            ? i18n.t("profile.success")
                            : i18n.t("profile.upload")}
                        </button>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-8 col-12">
              <div className="card">
                <div className="card-header">
                  {i18n.t("profile.companyInfo")}
                </div>
                <div className="card-body">
                  <Formik
                    onSubmit={(values, { setSubmitting }) => {
                      const payload = {
                        companyName: values.companyName,
                        contact: values.contact,
                        address: {
                          name: values.address.description,
                          location: {
                            coordinates: [
                              values.address.longitude,
                              values.address.latitude
                            ],
                            type: "Point"
                          }
                        },
                        location: {
                          coordinates: [
                            values.address.longitude,
                            values.address.latitude
                          ],
                          type: "Point"
                        },
                        introduction: values.introduction
                      };
                      Api.editMyCompanyInfo(payload, global._token).then(
                        json => {
                          if (json.__v === 0) {
                            toast.success("profile updated!", {
                              className: "alert alert-success"
                            });
                            setSubmitting(false);
                          }
                        }
                      );
                    }}
                    initialValues={{
                      companyName: this.state.data.companyName || "",
                      contact: this.state.data.contact || "",
                      introduction: this.state.data.introduction || "",
                      address: {
                        description:
                          (this.state.data.address &&
                            this.state.data.address.name) ||
                          "",
                        latitude:
                          (this.state.data.address &&
                            this.state.data.address.location &&
                            this.state.data.address.location.coordinates[1]) ||
                          0,
                        longitude:
                          (this.state.data.address &&
                            this.state.data.address.location &&
                            this.state.data.address.location.coordinates[0]) ||
                          0
                      }
                    }}
                    validationSchema={InfoSchema}
                    render={({ values, errors, touched, isSubmitting }) => (
                      <Form >
                        <div className="form-group">
                          <label>{i18n.t("profile.name")}<span className="text-danger"> *</span></label>
                          <Field
                            className={
                              "form-control" +
                              (errors.companyName && touched.companyName
                                ? " is-invalid"
                                : "")
                            }
                            name="companyName"
                          />
                          <ErrorMessage
                            className="invalid-feedback"
                            name="companyName"
                            component="div"
                          />
                        </div>
                        
                        <div className="form-group">
                          <label>{i18n.t("profile.address")}<span className="text-danger"> *</span></label>
                          <Field
                            name="address"
                            render={({ field, form }) => (
                              <PlacesAutocomplete
                                highlightFirstSuggestion={true}
                                value={this.state.addressValue}
                                debounce={600}
                                onChange={value => {
                                  this.setState({
                                    addressValue: value
                                  });
                                  form.setFieldValue("address.description", "");
                                  form.setFieldValue("address.latitude", 0);
                                  form.setFieldValue("address.longitude", 0);
                                }}
                                searchOptions={{
                                  componentRestrictions: {
                                    country: "ca"
                                  },
                                  types: ["address"]
                                }}
                                onSelect={(value, placeId) => {
                                  if (!placeId) {
                                  } else {
                                    geocodeByAddress(value)
                                      .then(result => {
                                        this.setState({
                                          addressValue:
                                            result[0].formatted_address
                                        });
                                        form.setFieldValue(
                                          "address.description",
                                          result[0].formatted_address
                                        );
                                        return getLatLng(result[0]);
                                      })
                                      .then(({ lat, lng }) => {
                                        form.setFieldValue(
                                          "address.latitude",
                                          lat
                                        );
                                        form.setFieldValue(
                                          "address.longitude",
                                          lng
                                        );
                                      });
                                  }
                                }}
                              >
                                {({
                                  getInputProps,
                                  suggestions,
                                  getSuggestionItemProps,
                                  loading
                                }) => (
                                  <div style={{ position: "relative" }}>
                                    <input
                                      {...getInputProps({
                                        placeholder: "Enter address",
                                        className:
                                          "form-control" +
                                          (errors.address && touched.address
                                            ? " is-invalid"
                                            : "")
                                      })}
                                    />
                                    <div
                                      className="card rounded border-0"
                                      style={{
                                        position: "absolute",
                                        top: "100%",
                                        zIndex: 1,
                                        marginTop: 8,
                                        marginBottom: 8
                                      }}
                                    >
                                      {/* <div className="card rounded border-0"> */}
                                      <div className="list-group">
                                        {loading && (
                                          <div className="list-group-item ">
                                            Loading...
                                          </div>
                                        )}
                                        {suggestions.map(suggestion => {
                                          const className = suggestion.active
                                            ? "list-group-item list-group-item-action active"
                                            : "list-group-item list-group-item-action";
                                          return (
                                            <div
                                              {...getSuggestionItemProps(
                                                suggestion,
                                                {
                                                  className
                                                }
                                              )}
                                            >
                                              <span>
                                                {suggestion.description}
                                              </span>
                                            </div>
                                          );
                                        })}
                                      </div>
                                      {/* </div> */}
                                    </div>
                                  </div>
                                )}
                              </PlacesAutocomplete>
                            )}
                          />
                          <ErrorMessage
                            className="invalid-feedback d-block"
                            name="address.latitude"
                            component="div"
                          />
                        </div>
                        <div className="form-group">
                          <label>{i18n.t("profile.contact")}</label>
                          <Field className="form-control" name="contact" />
                        </div>
                        <div className="form-group">
                          <label>{i18n.t("profile.introduction")}</label>
                          <Field
                            className="form-control"
                            name="introduction"
                            component="textarea"
                          />
                        </div>
                        <button
                          type="submit"
                          className="btn btn-primary"
                          disabled={isSubmitting}
                        >
                          {i18n.t("profile.save")}
                        </button>
                      </Form>
                    )}
                  />
                </div>
              </div>

              <div className="card mt-3">
                <div className="card-header">
                  {i18n.t("profile.changeEmail")}
                </div>
                <div className="card-body">
                  <Formik
                    onSubmit={(values, { setSubmitting }) => {
                      const payload = {
                        email: values.email,
                        code: values.code,
                        method: "email"
                      };
                      Api.changeAccount(payload, global._token).then(json => {
                        if (json.token) {
                          setSubmitting(false);
                        }
                      });
                    }}
                    initialValues={{
                      email: this.state.data.email || "",
                      code: ""
                    }}
                    validationSchema={EmailSchema}
                    render={({
                      isSubmitting,
                      errors,
                      touched,
                      values,
                      setFieldTouched
                    }) => (
                      <Form>
                        <div className="form-group">
                          <label>{i18n.t("profile.email")}</label>
                          <Field
                            name="email"
                            render={({ field }) => (
                              <div className="input-group">
                                <input
                                  {...field}
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors.email && touched.email
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <div className="input-group-append">
                                  <button
                                    type="button"
                                    className="btn btn-outline-secondary"
                                    onClick={() => {
                                      setFieldTouched("email", true);
                                      if (!errors.email) {
                                        this.sendCode(values.email, "email");
                                      }
                                    }}
                                    disabled={
                                      this.state.sendCodeEmailCount !== 60
                                    }
                                  >
                                    {this.state.sendCodeEmailCount === 60
                                      ? i18n.t("profile.sendCode")
                                      : this.state.sendCodeEmailCount}
                                  </button>
                                </div>
                                <ErrorMessage
                                  name="email"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                            )}
                          />
                        </div>
                        {this.state.showEmailCode ? (
                          <div className="form-group">
                            <label>{i18n.t("profile.enterCodeLabel")}</label>
                            <Field
                              name="code"
                              render={({ field }) => (
                                <>
                                  <input
                                    {...field}
                                    type="text"
                                    className={
                                      "form-control col-sm-4" +
                                      (errors.code && touched.code
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />

                                  <ErrorMessage
                                    name="code"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </>
                              )}
                            />
                          </div>
                        ) : null}
                        <button
                          type="submit"
                          className="btn btn-primary"
                          disabled={isSubmitting}
                        >
                          {i18n.t("profile.save")}
                        </button>
                      </Form>
                    )}
                  />
                </div>
              </div>

              <div className="card mt-3">
                <div className="card-header">
                  {i18n.t("profile.changePhoneNumber")}
                </div>
                <div className="card-body">
                  <Formik
                    initialValues={{
                      phone:
                        (this.state.data.mobile &&
                          this.state.data.mobile.length !== 0 &&
                          this.state.data.mobile.split(" ").length > 1 &&
                          this.state.data.mobile.split(" ")[1]) ||
                        "",
                      code: ""
                    }}
                    validationSchema={PhoneSchema}
                    onSubmit={(values, { setSubmitting }) => {
                      const payload = {
                        phone: values.phone,
                        code: values.code,
                        method: "simple",
                        countryCode: "1"
                      };
                      Api.changeAccount(payload, global._token).then(json => {
                        if (json._id) {
                          setSubmitting(false);
                        }
                      });
                    }}
                    render={({
                      isSubmitting,
                      errors,
                      touched,
                      values,
                      setFieldTouched
                    }) => (
                      <Form>
                        <div className="form-group">
                          <label>{i18n.t("profile.phoneNumber")}</label>
                          <Field
                            name="phone"
                            render={({ field }) => (
                              <div className="input-group">
                                <input
                                  {...field}
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors.phone && touched.phone
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <div className="input-group-append">
                                  <button
                                    type="button"
                                    className="btn btn-outline-secondary"
                                    onClick={() => {
                                      setFieldTouched("phone", true);
                                      if (!errors.phone) {
                                        this.sendCode(values.phone, "phone");
                                      }
                                    }}
                                    disabled={
                                      this.state.sendCodePhoneCount !== 60
                                    }
                                  >
                                    {this.state.sendCodePhoneCount === 60
                                      ? i18n.t("profile.sendCode")
                                      : this.state.sendCodePhoneCount}
                                  </button>
                                </div>
                                <ErrorMessage
                                  name="phone"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                            )}
                          />
                        </div>
                        {this.state.showPhoneCode ? (
                          <div className="form-group">
                            <label>{i18n.t("profile.enterCodeLabel")}</label>
                            <Field
                              name="code"
                              render={({ field }) => (
                                <>
                                  <input
                                    {...field}
                                    type="text"
                                    className={
                                      "form-control col-sm-4" +
                                      (errors.code && touched.code
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />

                                  <ErrorMessage
                                    name="code"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </>
                              )}
                            />
                          </div>
                        ) : null}
                        <button
                          type="submit"
                          className="btn btn-primary"
                          disabled={isSubmitting}
                        >
                          {i18n.t("profile.save")}
                        </button>
                      </Form>
                    )}
                  />
                </div>
              </div>
            </div>
            
          </div>
        </>
      );
    }
  }
}
